import { BaseFormField, FieldWrapper } from './Base'
import { Form } from 'react-bootstrap'

class TextInput extends BaseFormField {
    handleChange(event) {
        let dateTime = new Date(event.target.value).getTime()
        if (dateTime > 0) {
            super.setValue(Math.floor(dateTime / 1000))
        }
    }

    render() {
        const { field, value, required } = this.state

        let valueString

        let date = new Date(value * 1000)

        if (value && date) {
            valueString =
                date.toLocaleDateString('en-US', { year: 'numeric' }) +
                '-' +
                date.toLocaleDateString('en-US', { month: '2-digit' }) +
                '-' +
                date.toLocaleDateString('en-US', { day: '2-digit' })
        }

        return (
            <FieldWrapper field={field}>
                <Form.Control type="date" defaultValue={valueString} onChange={this.handleChange} required={required} />
            </FieldWrapper>
        )
    }
}

export default TextInput
