import { Component } from 'react'

class BackingInput extends Component {
    render() {
        return (
            <input
                type="number"
                className="d-none"
                name={'field-' + this.props.uid + '-backing'}
                required={true}
                value={this.props.validated ? 1 : ''}
                onChange={() => {}}
            />
        )
    }
}

export default BackingInput
