import EventBehavior from '../EventBehavior'
import Flag from 'country-flag-icons/react/3x2'

class Translator extends EventBehavior {
    translations = {}
    flag = 'GB'
    language = 'en'
    languages = []
    constructor() {
        super()

        if (navigator) {
            this.setLanguage(navigator.language)
        }

        this.loadTranslations({
            en: require('../helper/translations/en.json'),
            de: require('../helper/translations/de.json'),
        })
    }
    loadTranslations(translations) {
        this.translations = Object.assign(this.translations, translations)
    }
    setLanguage(languageString) {
        if (languageString && this.language !== languageString) {
            ;[this.language, this.flag] = languageString.split('-')
            this.dispatch('languageChanged')
        }
    }
    setLanguages(languages) {
        this.languages = languages
        this.dispatch('languagesChanged')
    }
    translate(string) {
        if (this.translations[this.language] && this.translations[this.language][string]) {
            return this.translations[this.language][string]
        }
        if (this.translations['en'] && this.translations['en'][string]) {
            return this.translations['en'][string]
        }
        return string
    }

    getLanguagesCount() {
        return this.languages.length
    }

    getLanguagesArray() {
        let languages = []

        this.languages.forEach((language) => {
            const [id, flag] = language.split('-')
            languages.push({
                id: id,
                flag: flag,
                name: t(id),
            })
        })

        return languages
    }

    getJsxFlag(flag) {
        const flagStyles = {
            height: 16,
        }
        if (!flag) {
            flag = this.flag
        }
        switch (flag.toLowerCase()) {
            case 'de':
                return <Flag.DE style={flagStyles} />
            case 'en':
            case 'gb':
                return <Flag.GB style={flagStyles} />
            case 'it':
                return <Flag.IT style={flagStyles} />
            default:
                return <></>
        }
    }
}

const translator = new Translator()

function t(string) {
    return translator.translate(string)
}

export { translator, t, Translator }
