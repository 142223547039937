import { BaseFormField, FieldWrapper } from './Base'
import clientStyles from '../ClientStyles'

class TextArea extends BaseFormField {
    render() {
        const { field, value, placeholder, required } = this.state

        return (
            <FieldWrapper field={field}>
                <textarea
                    // name={"field-" + field.getUid()}
                    className={'form-control ' + clientStyles.getTextinputClass()}
                    placeholder={placeholder}
                    onChange={this.handleChange}
                    defaultValue={value}
                    required={required}
                />
            </FieldWrapper>
        )
    }
}

export default TextArea
