import { translator } from './Translator'

class ClientParameters {
    data = {
        languages: [],
        app_header_image: '',
        background_color: '#ffffff',
        button_background_color: '#2a2a2a',
        comment_required_at_value: '2',
        create_tickets: 'no',
        dynamic_form: 'yes',
        enable_custom_stars: 'no',
        enable_styling: 'no',
        font_size: '3',
        footer_background_color: '#ffffff',
        footer_bgcolor: '#ffffff',
        footer_image_alignment: 'center',
        footer_image_block_height: '50',
        footer_image_width: '300',
        header_background_color: '#2a2a2a',
        header_image_alignment: 'center',
        header_image_block_height: '40',
        header_image_width: '300',
        hide_contactform_ids: ',128399,128399',
        hide_footer: 'no',
        hide_footer_logo_block: 'no',
        hide_footer_navigation: 'no',
        hide_header_navigation: 'no',
        hide_ifbck_logo: 'no',
        is_one_hover_mode: 'no',
        list_btn_background_color: '#eeeeee',
        page_background_color: '#f9f9f9',
        preset_font_family: '1',
        progress_bar: 'no',
        show_splahscreen: 'yes',
        skip_to_contactform: 'yes',
        stars_preset: 'default',
        styling_preset: 'flat_style',
        survey_width: '1024',
        text_color: '#000000',
        use_custom_font: 'no',
        custom_font_family: false,
        app_checkout_header: false,
        checkout_timeout: false,
    }

    loadData(data) {
        this.data = data

        if (data.language) {
            translator.setLanguage(data.language)
        }

        if (data.languages) {
            translator.setLanguages(data.languages.split(';'))
        }
    }
}

const clientParameters = new ClientParameters()

export default clientParameters
