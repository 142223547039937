import { BaseFormField, FieldWrapper } from './Base'
import clientStyles from '../ClientStyles'
import BackingInput from './helper/BackingInput'

class Checkbox extends BaseFormField {
    constructor(props) {
        super(props)

        Object.assign(this.state, {
            validated: this.state.value || false,
        })

        if (!this.state.value) {
            this.state.field.setValue(0)
        }
    }

    setValue(value) {
        super.setValue(value)
        this.setState({
            validated: value,
        })
    }

    render() {
        const { field, value, description, validated, required } = this.state

        return (
            <FieldWrapper field={field}>
                {required && !field.isHidden() && <BackingInput uid={field.getUid()} validated={validated} />}
                <div className={'form-check w-100 ' + clientStyles.getCheckboxClass()}>
                    <input
                        className={'form-check-input'}
                        // name={'field-' + field.getUid()}
                        type="checkbox"
                        id={'field-' + field.getUid() + '-checkbox'}
                        onChange={(e) => {
                            this.setValue(e.target.checked ? 1 : 0)
                        }}
                        defaultChecked={Boolean(value)}
                        required={required}
                    />
                    <label
                        className="form-check-label"
                        htmlFor={'field-' + field.getUid() + '-checkbox'}
                        dangerouslySetInnerHTML={{ __html: description }}
                    ></label>
                </div>
            </FieldWrapper>
        )
    }
}

export default Checkbox
