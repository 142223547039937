import React from 'react'

import client from './Client'
import clientStyles from './ClientStyles'
import CampaignFormField from './CampaignFormField'
import { t } from './Translator'
import { Form } from 'react-bootstrap'

import './CampaignForm.css'
import Header from './Header'
import FormStyles from './FormStyles'
import Logger from '../helper/Logger'

export default class CampaignForm extends React.Component {
    campaignId = 0

    constructor(props) {
        super(props)

        this.campaignId = props.campaignId

        this.state = {
            client: client,
            currentPage: 0,
            validated: false,
            pages: client.getPagedCampiagnFields(this.campaignId),
        }

        this.formRef = React.createRef()
    }

    nextPage() {
        this.setState({
            currentPage: this.state.currentPage + 1,
        })
    }

    previousPage() {
        this.setState({
            currentPage: this.state.currentPage - 1,
        })
    }

    setValidated(status) {
        this.setState({
            validated: status,
        })
    }

    render() {
        const { currentPage, validated, pages } = this.state

        const handleSubmit = (event) => {
            Logger.log('submitting form / handling paging')
            const form = event.currentTarget

            event.preventDefault()
            event.stopPropagation()

            handlePaging(form)
        }

        const handlePaging = (form) => {
            if (form.checkValidity() === false) {
                this.setValidated(true)
                return
            }

            if (pages.length > currentPage + 1) {
                this.setValidated(false)
                this.nextPage()
                return
            }

            client.submitFeedback()
        }

        client.nextPage = () => {
            handlePaging(this.formRef.current)
        }

        client.pagesChanged = () => {
            this.setState({
                pages: client.getPagedCampiagnFields(this.campaignId),
            })
        }

        return (
            <>
                <Header />
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    className="feedback-form"
                    ref={this.formRef}
                >
                    {pages.map(
                        (page, index) =>
                            currentPage === index && (
                                <div key={'page-' + index} className={page.id}>
                                    {page.items.map((field) => (
                                        <CampaignFormField field={field} key={'field-container' + field.uid} />
                                    ))}
                                </div>
                            )
                    )}
                    <div className="row justify-content-center mb-3">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8 text-center">
                            <br />
                            <br />

                            {pages.length > 1 && (
                                <>
                                    <a
                                        href={':'}
                                        className={
                                            'btn btn-primary' +
                                            clientStyles.getButtonClass() +
                                            (currentPage === 0 ? ' disabled' : '')
                                        }
                                        onClick={(e) => {
                                            if (currentPage === 0) {
                                                return
                                            }
                                            e.preventDefault()
                                            this.previousPage()
                                        }}
                                    >
                                        &laquo; {t('Previous')}
                                    </a>
                                    &nbsp; &nbsp;
                                </>
                            )}

                            {pages.length > currentPage + 1 && (
                                <button className={'btn btn-primary' + clientStyles.getButtonClass()} name="submit">
                                    {t('Next')} &raquo;
                                </button>
                            )}

                            {pages.length === currentPage + 1 && (
                                <button className={'btn btn-primary' + clientStyles.getButtonClass()} name="submit">
                                    {t('Submit')}
                                </button>
                            )}
                        </div>
                    </div>
                </Form>

                <FormStyles />
                <br />
            </>
        )
    }
}
