import React, { useState } from 'react'
// import logo from './logo.svg'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.js'

import AppConfig from './AppConfig'
import Navbar from 'react-bootstrap/NavBar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import IdleTimer from 'react-idle-timer'
import AppRouter from './AppRouter'
import urlManager from './ifeedback/UrlManager'
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom'
import { ArrowLeft } from 'react-bootstrap-icons'
import { Button, Modal } from 'react-bootstrap'
import syncer from './ifeedback/Syncer'
import { t, translator } from './ifeedback/Translator'
import client from './ifeedback/Client'
import clientStyles from './ifeedback/ClientStyles'
import qs from 'qs'
import Logger from "./helper/Logger";

// for manifest.json See https://developers.google.com/web/fundamentals/web-app-manifest/

class App extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            url: urlManager.getEntry(),
            urlManager: urlManager,
            language: translator.language,
        }
    }

    componentDidMount() {
        translator.on('languageChanged', () => {
            this.setState({ language: translator.language })
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState(urlManager)
        }
    }

    render() {
        const { url, language } = this.state

        return (
            <>
                <pre className="d-none">
                    Version: {AppConfig.version}
                    Language: {language}
                </pre>
                <Router>
                    <Navbar expand="md" bg="dark" variant="dark" sticky="top">
                        <Container fluid="lg">
                            <Nav className="w-25 text-start">
                                <BackButton />
                            </Nav>
                            <Navbar.Brand className="justify-content-center">iFEEDBACK®</Navbar.Brand>
                            <Nav className="w-25 justify-content-end">
                                <LanguageSwitcher />
                            </Nav>
                        </Container>
                    </Navbar>

                    <Container fluid="lg" bg="light" variant="light" className="bg-light mainContainer">
                        {(url !== '/' && (
                            <Switch>
                                <Route path={(AppConfig.baseUrl ? '/' : '') + AppConfig.baseUrl + url + '/locations/:locationId/campaigns/:campaignId'}>
                                    <AppRouter />
                                </Route>
                                <Route path={(AppConfig.baseUrl ? '/' : '') + AppConfig.baseUrl + url + '/locations/:locationId'}>
                                    <AppRouter />
                                </Route>
                                <Route path={(AppConfig.baseUrl ? '/' : '') + AppConfig.baseUrl + url + ''}>
                                    <AppRouter />
                                </Route>
                            </Switch>
                        )) || <Home />}
                    </Container>
                    <Container bg="dark" fluid className="container-fluid bg-dark d-none">
                        <Container variant="dark" />
                    </Container>
                </Router>
                <IdleTimer
                    timeout={1000 * 60 * 5}
                    startOnMount={false}
                    onIdle={() => {
                        Logger.log('idle timeout', 'AppConfig')
                        client.idleTimeout()
                    }}
                />
                <AppUpdateModal />
            </>
        )
    }
}

function Home() {
    return (
        <div>
            <h2>Home</h2>

            <div className="row mb-3 justify-content-center">
                <label htmlFor="field-119040" className="form-label col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                    Insert entrypoint
                </label>

                <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6">
                    <input id="entryInput" name="field-119040" type="text" className="form-control mb-3" placeholder="entrypoint" />
                    <button
                        className="btn btn-primary w-100"
                        onClick={() => {
                            window.location.href = document.getElementById('entryInput').value + ''
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

function AppUpdateModal() {
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)

    syncer.onAppUpdateAvailable = () => {
        setShow(true)
    }

    return (
        <Modal size="sm" show={show} onHide={handleClose} aria-labelledby="Update">
            <Modal.Header>
                <Modal.Title>Update Available</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button
                    variant="outline-primary"
                    onClick={() => {
                        window.location.href = urlManager.getEntry()
                    }}
                >
                    Update
                </Button>
                <Button
                    variant="outline-secondary"
                    onClick={() => {
                        handleClose()
                    }}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function BackButton() {
    const [count, setCount] = useState(0)

    const history = useHistory()

    history.listen(() => {
        setCount((count) => count + 1)
    })
    const forceShowBack = qs.parse(window.location.search, { ignoreQueryPrefix: true }).showBack

    return (
        (((count && !urlManager.isBase()) || forceShowBack) && (
            <Button variant="dark" className="text-start" onClick={urlManager.goBack}>
                <ArrowLeft />
            </Button>
        )) ||
        null
    )
}

function LanguageSwitcher() {
    const [languagesCount, setCount] = useState(0)

    translator.on('languagesChanged', () => {
        setCount(translator.getLanguagesCount())
    })

    const changeLanguage = (language) => {
        translator.setLanguage(language)
    }

    let buttonText = translator.getJsxFlag(),
        buttonClass = ''

    if (clientStyles.wantsLanguageName) {
        buttonText = t(translator.language)
        buttonClass = ' dropdown-toggle'
    }

    return (
        <>
            {languagesCount > 1 && (
                <ul className="navbar-nav">
                    <li className="nav-item dropdown text-end">
                        <a href={':'} className={'nav-link' + buttonClass} id="language-dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {buttonText}
                        </a>
                        <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="language-dropdown">
                            {translator.getLanguagesArray().map((language) => (
                                <li key={language.id}>
                                    <button
                                        className={'dropdown-item' + (translator.language === language.id ? ' active' : '')}
                                        onClick={() => {
                                            changeLanguage(language.id + '-' + language.flag)
                                        }}
                                    >
                                        {translator.getJsxFlag(language.flag)}&nbsp;{language.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
            )}
        </>
    )
}

export default App
