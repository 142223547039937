import { BaseFormField, FieldWrapper } from './Base'
import StarRatingInput from './helper/StarRatingInput'

class StarRating extends BaseFormField {
    // texts = ['Please select ...', 'Very unsatisfied', 'Unsatisfied', 'About average', 'Satisfied', 'Very satisfied']

    constructor(props) {
        super(props)

        this.state = Object.assign(this.state, {
            captions: this.state.field.getOptions(),
        })
    }

    languageChanged() {
        super.languageChanged()

        this.setState({
            captions: this.state.field.getOptions(),
        })
    }

    render() {
        const { field, value, captions, placeholder, required } = this.state

        return (
            <FieldWrapper
                field={field}
                centeredStyle={field.data.settings && parseInt(field.data.settings.rating_position) === 2}
                className={field.data.settings.label_position ? 'star-labels-' + field.data.settings.label_position : ''}
            >
                <StarRatingInput
                    // name={'field-' + field.getUid()}
                    value={value}
                    onChange={this.setValue}
                    captions={captions}
                    placeholder={placeholder}
                    required={required}
                    settings={field.data.settings}
                />
            </FieldWrapper>
        )
    }
}

export default StarRating
