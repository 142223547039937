import BaseFormField, { FieldWrapper } from './Base'
import clientStyles from '../ClientStyles'

class AppDivider extends BaseFormField {
    render() {
        return (
            <FieldWrapper field={this.state.field} hideLabel={true} hideWrapper={true}>
                <div className="col-12 col-sm-10 col-md-9 col-lg-8 col-xl-9">
                    <br />
                    <h4 className={clientStyles.getHeadlineClass()}>{this.state.field.getLabel()}</h4>
                    <hr />
                </div>
            </FieldWrapper>
        )
    }
}

export default AppDivider
