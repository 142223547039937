import { Component } from 'react'

class LoadingAction extends Component {
    render() {
        return (
            <>
                <br />
                <br />
                <br />
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </>
        )
    }
}

export default LoadingAction
