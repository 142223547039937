import { t, translator } from './Translator'
import client from './Client'
import EventBehavior from '../EventBehavior'
import Logger from '../helper/Logger'

class FieldObject extends EventBehavior {
    data = {
        uid: null,
        field_type: null,
        label: null,
        description: null,
        html: null,
        placeholder: null,
        settings: null,
        options: null,
        value: '',

        required: 0,

        // localizations
        descriptions: {},
        labels: {},
        localizations: {},
    }

    hidden = 0

    constructor(data) {
        super()
        this.data = data
        this.data.value = data.value || ''
        this.loadLanguage(translator.language)
        this.hidden = data.settings && data.settings.hidden ? 1 : 0
        data.object = this
    }

    loadLanguage(language) {
        // general overwrite
        if (this.data.localizations && this.data.localizations[Object.keys(this.data.localizations)[0]]) {
            Object.assign(this.data, this.data.localizations[Object.keys(this.data.localizations)[0]])
        }

        // common way
        if (this.data.localizations && this.data.localizations[language]) {
            Object.assign(this.data, this.data.localizations[language])
        }

        if (this.data.descriptions && this.data.descriptions[language]) {
            this.data.description = this.data.descriptions[language]
        }
        if (this.data.labels && this.data.labels[language]) {
            this.data.labels = this.data.labels[language]
        }
        if (this.data.options && this.data.options[language]) {
            this.data.options = this.data.options[language]
        }
    }

    setValue(value) {
        Logger.log('setting value: ' + value, 'FieldObject')
        this.data.value = value
    }

    getUid() {
        return parseInt(this.data.uid)
    }

    getType() {
        return parseInt(this.data.field_type)
    }

    getLabel() {
        switch (this.getType()) {
            case 2: // checkbox
                if (this.data.html && this.data.html.trim() !== '' && (!this.data.label || this.data.label.trim() === '')) {
                    return this.data.description.trim()
                }
                return this.data.label && (this.data.description || this.data.html) ? this.data.label.trim() : 'sp'
            case 10: // checkbox list
            case 1: // dropdown
            case 9: // star rating
                return (this.data.label && this.data.label.trim() !== '') || (this.data.description && this.data.description.trim())
            default:
                return this.data.label ? this.data.label.trim() : ''
        }
    }

    getDescription() {
        switch (this.getType()) {
            case 2: // checkbox
            case 10: // checkbox list
                if (this.data.html && this.data.html.trim()) return this.data.html.trim()
                if (this.data.description && this.data.description.trim()) return this.data.description.trim()
                return this.data.label && this.data.label.trim()
            default:
                return this.data.description
        }
    }

    getPlaceholder() {
        if (this.getType() === 9) {
            return this.getOptions()[0]
        }
        return this.data.placeholder
    }

    getOptions() {
        switch (this.getType()) {
            case 1: // dropdown
                return Array.isArray(this.data.options) ? this.data.options : []

            case 9: // star rating
                if (this.data.options) {
                    return this.data.options.split(';')
                }
                break
            case 10: // checkbox list
            case 20: // scale
                if (this.data.options) {
                    let opts = this.data.options.split(';')

                    if (opts.length > 1) {
                        opts.shift()
                    }
                    if (opts.length > 1) {
                        opts.shift()
                    }

                    return opts
                }
                break
            default:
                return []
        }

        return []
    }

    getHtml() {
        return this.data.html
    }

    getMinMaxOptions() {
        const [min, max] = this.data.options.split(';')

        return [min, max]
    }

    getFieldClasses() {
        return '' // shadow
    }

    customFieldWrapperClasses() {
        switch (this.getType()) {
            case 0: // text input
            case 4: // email input
                return ''
            case 1: // dropdown
                return ''
            case 2: // checkbox
            case 3: // contact checkbox (old)
            case 6: // newsletter checkbox (old)
            case 7: // lottery checkbox (old)
                return !this.getLabel() ? ' offset-xl-3' : ''
            case 8: // text area
            case 9: // rating
            case 20: // scale
            case 16: // app divider
            case 10: // checkbox list
            case 15: // date picker
            default:
                return ''
        }
    }
    isRequired() {
        if (this.getType() === 10) {
            const [min, max] = this.getMinMaxOptions()
            return Boolean(min || max)
        }
        return (this.data.required || false) && !this.isHidden()
    }

    getOptionName(option) {
        if (option.localizations && option.localizations[translator.language]) {
            return option.localizations[translator.language]
        }
        if (option.localizations && option.localizations.en) {
            return option.localizations.en
        }
        return option.name
    }

    getInvalidText() {
        if (this.getType() === 10) {
            const [min, max] = this.getMinMaxOptions()

            let string = t('Please select at least |x| up to |y| options.')

            if (!max || parseInt(max) === this.getOptions().length) {
                string = t('Please select at least |x| options.')

                if (parseInt(min) === 1) string = t('Please select at least one option.')
            }

            if (!min) {
                string = t('Please select a maximum of |y| options.')

                if (parseInt(max) === 1) string = t('Please select only one option.')
            }

            return string.replace('|x|', min).replace('|y|', max)
        }
        return t('This field is required.')
    }

    isHidden() {
        return this.hidden === 1
    }

    show() {
        this.data.settings.hidden = false
        this.hidden = 0
        this.dispatch('visualChanged')
    }

    hide() {
        this.data.settings.hidden = true
        this.hidden = 1
        this.dispatch('visualChanged')
    }

    performActions() {
        if (this.data.settings && this.data.settings.actions) {
            this.data.settings.actions.forEach(this.performAction.bind(this))
        }
    }

    performAction(action) {
        let conditionsMatch = true

        if (action.conditions) {
            action.conditions.forEach((condition) => {
                conditionsMatch = conditionsMatch && this.checkCondition(condition)
            })
        }

        if (conditionsMatch && action.action) {
            if (action.action === 'SHOW') {
                client.showField(action.field)
            }
            if (action.action === 'HIDE') {
                client.hideField(action.field)
            }
            if (action.action === 'NEXT') {
                client.nextPage()
            }
            if (action.action === 'SEND') {
                client.submitFeedback()
            }
        }
    }

    checkCondition(condition) {
        let translatedValue = this.data.value

        if (this.getType() === 1) {
            // for dropdown translate into index
            this.data.options.forEach((option, index) => {
                if (option.uid === this.data.value) {
                    translatedValue = index
                }
            })
        }

        if (condition.compare === '>=') {
            return translatedValue >= condition.value
        }

        if (condition.compare === '<=') {
            return translatedValue <= condition.value
        }

        if (condition.compare === '>') {
            return translatedValue > condition.value
        }

        if (condition.compare === '<') {
            return translatedValue < condition.value
        }

        if (condition.compare === '!=') {
            return String(translatedValue) !== String(condition.value)
        }

        if (condition.compare === '=') {
            return String(translatedValue) === String(condition.value)
        }

        return false
    }
}

export default FieldObject
