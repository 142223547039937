import React from 'react'

import { withRouter } from 'react-router-dom'

// iFeedback components
import client from './ifeedback/Client'
import urlManager from './ifeedback/UrlManager'
import CampaignForm from './ifeedback/CampaignForm'
import PlaceSelection from './ifeedback/PlaceSelection'
import CampaignSelection from './ifeedback/CampaignSelection'
import Checkout from './ifeedback/Checkout'
import LoadingAction from './ifeedback/LoadingAction'

const currentUrl = urlManager.getEntry()

class AppRouter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            isLoaded: false,
            client: client,
        }
    }

    componentDidMount() {
        const that = this

        let updateCallback = () => {
            that.setState({
                isLoaded: true,
                client,
            })
        }

        client.on(client.eventNames.clientUpdated, updateCallback)
        client.on(client.eventNames.placeChanged, updateCallback)

        client.getData(currentUrl, this.props.match.params.locationId, this.props.match.params.campaignId).then(
            () => {},
            (error) => {
                this.setState({ isLoaded: true, error })
            }
        )
    }

    componentWillUnmount() {
        client.off(client.eventNames.clientUpdated)
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged()
        }
    }

    onRouteChanged() {
        client.selectCampaign(this.props.match.params.campaignId)

        client.selectLocation(this.props.match.params.locationId)
    }

    render() {
        const { error, isLoaded, client } = this.state

        if (!isLoaded) {
            return <LoadingAction />
        }

        if (error) {
            return (
                <>
                    <h3>Error</h3>
                    <div>Error: {error.message}</div>
                </>
            )
        }

        if (client.isCheckout) {
            return <Checkout />
        }

        if (client.isCampaign()) {
            return <CampaignForm campaignId={client.getCurrentCampaignId()} />
        }

        if (client.isPlace()) {
            return <CampaignSelection />
        }

        return <PlaceSelection />
    }
}

export default withRouter(AppRouter)
