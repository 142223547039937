import client from './Client'

class ClientStyles {
    isTerminal() {
        return Boolean(client.data && client.data.entry && client.data.entry.extendedinfo)
    }

    getFieldWrapperClasses(centered = false) {
        if (centered) {
            return 'col-12 col-sm-10 col-md-9 col-lg-9 col-xl-9'
        }
        return 'col-12 col-sm-10 col-md-9 col-lg-8 col-xl-6'
    }

    getLabelClass() {
        if (this.isTerminal()) {
            return ' fs-3'
        }

        return ''
    }

    getButtonClass() {
        if (this.isTerminal()) {
            return ' btn-lg'
        }

        return ''
    }

    getDropdownClass() {
        if (this.isTerminal()) {
            return ' form-select-lg'
        }

        return ''
    }

    getCheckboxClass() {
        if (this.isTerminal()) {
            return ' form-switch fs-4'
        }

        return ' form-switch'
    }

    getTextinputClass() {
        if (this.isTerminal()) {
            return ' form-control-lg'
        }

        return ''
    }

    getHeadlineClass() {
        if (this.isTerminal()) {
            return 'h3'
        }

        return ''
    }

    getStarSize() {
        if (this.isTerminal()) {
            return 'xl'
        }

        return 'lg'
    }
}

const clientStyles = new ClientStyles()

export default clientStyles
