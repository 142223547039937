import { BaseFormField, FieldWrapper } from './Base'
import { Fragment } from 'react'
import clientStyles from '../ClientStyles'
import BackingInput from './helper/BackingInput'

class ScaleField extends BaseFormField {
    constructor(props) {
        super(props)

        Object.assign(this.state, {
            options: this.state.field.getOptions(),
            descriptions: this.state.field.getMinMaxOptions(),
            validated: this.state.value || !this.state.field.isRequired(),
        })
    }

    languageChanged() {
        this.setState({
            options: this.state.field.getOptions(),
            descriptions: this.state.field.getMinMaxOptions(),
        })
    }

    visualChanged() {
        super.visualChanged()

        this.setState({
            validated: this.state.value || !this.state.field.isRequired(),
        })
    }

    handleChange(event) {
        super.handleChange(event)
        this.setState({
            validated: true,
        })
    }

    render() {
        const { field, value, options, validated, required, descriptions } = this.state

        return (
            <FieldWrapper field={field}>
                {required && !field.isHidden() && <BackingInput uid={field.getUid()} validated={validated} />}
                {descriptions[0] && (
                    <div className="text-start float-start">
                        {descriptions[0]}
                        <br />▼
                    </div>
                )}
                {descriptions[1] && (
                    <div className="text-end float-end">
                        {descriptions[1]}
                        <br />▼
                    </div>
                )}
                <div className={'btn-group w-100 bg-white validate-nps' + field.getFieldClasses()} role="group">
                    {options.map((option, index) => (
                        <Fragment key={'field-' + field.getUid() + '-option-' + index}>
                            <input
                                type="radio"
                                className="btn-check"
                                name={'field-' + field.getUid()} // name seems to be required for radios
                                id={'field-' + field.getUid() + '-option-' + index}
                                value={index}
                                autoComplete="off"
                                onChange={this.handleChange}
                                defaultChecked={parseInt(value) === index}
                                required={required}
                            />
                            <label
                                className={'btn btn-outline-secondary ' + clientStyles.getButtonClass()}
                                htmlFor={'field-' + field.getUid() + '-option-' + index}
                            >
                                {option}
                            </label>
                        </Fragment>
                    ))}
                </div>
            </FieldWrapper>
        )
    }
}

export default ScaleField
