import { Component } from 'react'
import clientParameters from './ClientParameters'

class FormStyles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            styles: clientParameters.data,
        }
    }

    getCssStyle(container, attributes, values, important) {
        let attributesArray = Array.isArray(attributes) ? attributes : (attributes || '').split(',')

        let attributeValuesString = ''

        attributesArray.forEach((attribute, index) => {
            let value = !Array.isArray(values) ? values : values[index]
            attributeValuesString += '' + attribute + ': ' + value + (important ? '!important' : '') + ';\n'
        })

        return container + ' {\n' + attributeValuesString + '}'
    }

    componentDidMount() {
        this._isMounted = true

        window.onmessage = (event) => {
            if (!event.data || typeof event.data === 'object') {
                return
            }

            const info = JSON.parse(event.data)

            if (info.info !== 'styleUpdate') {
                return
            }

            let styles = this.state.styles

            styles.enable_styling = 'yes'

            if (info.target === 'background') {
                styles.background_color = info.value
            }
            if (info.target === 'pageBackground') {
                styles.page_background_color = info.value
            }
            if (info.target === 'textColor') {
                styles.text_color = info.value
            }
            if (info.target === 'buttonBackground') {
                styles.button_background_color = info.value
            }
            if (info.target === 'surveyFields') {
                styles.list_btn_background_color = info.value
            }
            if (info.target === 'headerColor') {
                styles.header_background_color = info.value
            }

            this.setState({
                styles: styles,
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    loadCustomFont() {
        return this.getCssStyle('@font-face', ['font-family', 'src'], ['customFont', 'url("https://ifbck.com/' + this.state.styles.custom_font_family + '")'])
            + this.getCssStyle('body', 'font-family', 'customFont, Arial, Helvetica')
    }

    render() {
        const { styles } = this.state

        if (styles.enable_styling !== 'yes') {
            return <></>
        }

        return (
            <>
                <style>
                    {styles.page_background_color &&
                        this.getCssStyle('#root', 'background-color', styles.page_background_color)}
                    {styles.background_color &&
                        this.getCssStyle('#root .mainContainer', 'background-color', styles.background_color, true)}
                    {styles.button_background_color &&
                        this.getCssStyle(
                            '.btn-primary,.btn-primary.disabled',
                            'background-color,border-color',
                            styles.button_background_color
                        )}
                    {styles.list_btn_background_color &&
                        this.getCssStyle(
                            '.form-control,.form-select,.btn-group.bg-white',
                            'background-color',
                            styles.list_btn_background_color,
                            true
                        ) + this.getCssStyle('.btn-group.bg-white', 'border-radius', '.25rem')}
                    {styles.text_color &&
                        this.getCssStyle('body', 'color', styles.text_color) +
                            this.getCssStyle('.form-select,.btn-outline-secondary', 'color', styles.text_color)}
                    {styles.header_background_color && (
                        <>
                            {this.getCssStyle(
                                '.navbar.bg-dark',
                                'background-color',
                                styles.header_background_color,
                                true
                            )}
                            {styles.header_background_color === '#ffffff' && (
                                <>
                                    {this.getCssStyle('.navbar.bg-dark *', 'color', '#000000', true)}
                                    {this.getCssStyle('.navbar.bg-dark *', 'background-color', '#ffffff', true)}
                                </>
                            )}
                        </>
                    )}

                    {styles.custom_font_family && this.loadCustomFont()}
                </style>
                {styles.css && (<style dangerouslySetInnerHTML={{ __html: styles.css }}/>)}
            </>
        )
    }
}

export default FormStyles
