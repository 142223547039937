import { Component } from 'react'
import jQuery from 'jquery'
import 'bootstrap-star-rating'
import 'bootstrap-star-rating/js/locales/de'
import 'bootstrap-star-rating/js/locales/it'
import 'bootstrap-star-rating/css/star-rating.min.css'
import './StarRatingInput.css'
import { Star, StarFill } from 'react-bootstrap-icons'
import reactDOMServer from 'react-dom/server'
import ClientStyles from '../../ClientStyles'
import { translator } from '../../Translator'

// for help: https://plugins.krajee.com/star-rating/plugin-options

class StarRatingInput extends Component {
    isSingleHover = false

    size = 5

    onChange = () => {}

    constructor(props) {
        super(props)

        if (props.onChange) {
            this.onChange = props.onChange
        }

        if (parseInt(this.props.settings.single_hover) === 1) {
            this.isSingleHover = true
        }

        if (this.props.settings.size) {
            this.size = this.props.settings.size
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.$el.rating('refresh', {
            starCaptions: this.props.captions,
            clearCaption: this.props.placeholder,
        })

        if (this.props.settings) {
            this.updateSettings(this.props.settings)
        }
    }

    componentDidMount() {
        let ratingScale = this.props.settings.hasOwnProperty('rating_scale') ? this.props.settings.rating_scale ?? 5 : 5
        this.$el = jQuery(this.el)
        this.$el.rating({
            emptyStar: reactDOMServer.renderToString(<Star />),
            filledStar: reactDOMServer.renderToString(<StarFill />),
            showClear: false,
            size: ClientStyles.getStarSize(),
            showCaptionAsTitle: false,
            captionElement: 'div.hidden',
            step: 1,
            stars: ratingScale,
            max: ratingScale,
            language: translator.language,
            starCaptions: this.props.captions,
            clearCaption: this.props.placeholder,
            animate: !this.isSingleHover,
            containerClass: 'size-' + this.size + ' stars-' + ratingScale,
        })
        const ratingParent = this.$el.parent()
        const that = this
        this.$el.on('rating:change', (event, value) => {
            this.onChange(value)
            if (that.isSingleHover) {
                ratingParent.find('.filled-stars .star').css('opacity', 0)
                ratingParent.find('.filled-stars .star:eq(' + (value - 1) + ')').css('opacity', 1)
            }
        })
        if (this.isSingleHover) {
            this.$el.on('rating:hover', function (event, value) {
                ratingParent.find('.filled-stars .star').css('opacity', 0)
                ratingParent.find('.filled-stars .star:eq(' + (value - 1) + ')').css('opacity', 1)
            })
            this.$el.on('rating:hoverleave', function (event) {
                ratingParent.find('.filled-stars .star').css('opacity', 0)
                ratingParent.find('.filled-stars .star:eq(' + (event.target.value - 1) + ')').css('opacity', 1)
            })
        }

        let wrapper = this.$el.parent()

        let minWidth = 25 * ratingScale + 4 * ratingScale

        const resize = () => {
            const maxWidth = wrapper.width() * 0.6

            // Calculate the width based on the scale
            const widthRange = maxWidth - minWidth
            const width = minWidth + (this.size / 10) * widthRange

            wrapper.css('--star-screen-scale', (width/minWidth).toFixed(1))
        }

        jQuery(window).on('resize', resize)

        resize()

        if (this.props.settings) {
            this.updateSettings(this.props.settings)
        }

        // tried to make some smoother stars without border pixels, rating:mouseleave does not work as expected unfortunately
        // this.$el.on('rating:hover', (event, value, caption, target) => {
        //     this.$el.parent().find('.rating-container .empty-stars').width(100 - (20 * value) + '%')
        // })
        // this.$el.on('rating:mouseleave', (event, index, status, $star) => {
        //     this.$el.parent().find('.rating-container .empty-stars').width(100 - (20 * index) + '%')
        // })
    }

    updateSettings(settings) {
        const ratingParent = this.$el.parent()

        if (settings.hasOwnProperty('s_stars')) {
            jQuery(settings.s_stars).each((i, e) => {
                ratingParent.find('.filled-stars .star:eq(' + i + ')').html('<img src="//ifbck.com' + e + '" alt="⭑"/>')
            })
        }

        if (settings.hasOwnProperty('us_stars')) {
            jQuery(settings.us_stars).each((i, e) => {
                ratingParent.find('.empty-stars .star:eq(' + i + ')').html('<img src="//ifbck.com' + e + '" alt="⭒"/>')
            })
        }
    }

    render() {
        return <>
            <input type="number" ref={(el) => (this.el = el)} name={this.props.name} defaultValue={this.props.value} required={this.props.required} />
        </>
    }
}

export default StarRatingInput
