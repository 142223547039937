import { BaseFormField, FieldWrapper } from './Base'

class Html extends BaseFormField {
    render() {
        const { field } = this.state

        const html = field.getHtml().trim()

        if (!html) {
            return <></>
        }

        return (
            <FieldWrapper field={field}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </FieldWrapper>
        )
    }
}

export default Html
