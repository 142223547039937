import { BaseFormField, FieldWrapper } from './Base'
import clientStyles from '../ClientStyles'

class Dropdown extends BaseFormField {
    render() {
        const { field, value, required } = this.state

        return (
            <FieldWrapper field={field}>
                <select
                    className={'form-control form-select selectpicker ' + clientStyles.getDropdownClass()}
                    // name={'field-' + field.getUid()}
                    id={'field-' + field.getUid() + '-dropdown'}
                    onChange={this.handleChange}
                    defaultValue={value}
                    required={required}
                >
                    {field.getOptions().map((option, index) => (
                        <option
                            key={'field-' + field.getUid() + '-option-' + (option.id || option.uid)}
                            value={index === 0 ? '' : option.id || option.uid}
                        >
                            {field.getOptionName(option)}
                        </option>
                    ))}
                </select>
            </FieldWrapper>
        )
    }
}

export default Dropdown
