import BaseFormField from './fieldRenderer/Base'

// field types
import TextInput from './fieldRenderer/TextInput'
import TextArea from './fieldRenderer/TextArea'
import ScaleField from './fieldRenderer/ScaleField'
import Checkbox from './fieldRenderer/Checkbox'
import Dropdown from './fieldRenderer/Dropdown'
import StarRating from './fieldRenderer/StarRating'
import AppDivider from './fieldRenderer/AppDivider'
import CheckboxList from './fieldRenderer/CheckboxList'
import DateInput from './fieldRenderer/DateInput'
import Html from './fieldRenderer/Html'

class CampaignFormField extends BaseFormField {
    render() {
        const { field } = this.state

        if (field) {
            switch (field.getType()) {
                case 0: // text input
                case 4: // email input
                    return <TextInput field={field} />
                case 1: // dropdown
                    return <Dropdown field={field} />
                case 2: // checkbox
                case 3: // contact checkbox (old)
                case 6: // newsletter checkbox (old)
                case 7: // lottery checkbox (old)
                    return <Checkbox field={field} />
                case 8: // text area
                    return <TextArea field={field} />
                case 9: // rating
                    return <StarRating field={field} />
                case 20: // scale
                    return <ScaleField field={field} />
                case 16: // app divider
                    return <AppDivider field={field} />
                case 10: // checkbox list
                    return <CheckboxList field={field} />
                case 11: // TODO: photo upload
                    return <></>
                case 15: // date picker
                    return <DateInput field={field} />
                case 5:
                    return <Html field={field} />
                default:
                    return (
                        <div
                            key={'empty-field' + field.getUid()}
                            className="d-none"
                            data-info="unsupported field"
                            // data-field={JSON.stringify(field.data)}
                        />
                    )
            }
        }
    }
}

export default CampaignFormField
