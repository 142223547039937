import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'

import client from './Client'
import urlManager from './UrlManager'
import clientStyles from './ClientStyles'

class CampaignSelection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            client: client,
            campaigns: client.getCampaignsList(),
        }
    }

    selectCampaign(uid) {
        this.props.history.push(urlManager.getEntry() + '/locations/' + client.currentPlace.uid + '/campaigns/' + uid)
    }

    render() {
        const { client, campaigns } = this.state

        return (
            <>
                <br />
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                        <h3>{client.getPlaceName()}</h3>

                        <ListGroup className="shadow campaign-list">
                            {campaigns.map((item) => (
                                <ListGroup.Item
                                    action
                                    key={item.uid}
                                    className={clientStyles.getCheckboxClass()}
                                    onClick={() => {
                                        this.selectCampaign(item.uid)
                                    }}
                                >
                                    {item.name}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                </div>

                <br />
                <br />
            </>
        )
    }
}

export default withRouter(CampaignSelection)
