// import crypto from 'crypto'
import Api from '../Api'
import client from './Client'
import AppConfig from '../AppConfig'
import Logger from '../helper/Logger'

class Syncer {
    identifier = ''
    battery = 0
    version = AppConfig.version
    syncsDone = 0

    onAppUpdateAvailable = () => {}

    constructor() {
        if (navigator.getBattery) {
            const handleChange = ({ target: { level } }) => {
                this.battery = level * 100
            }
            let battery
            navigator.getBattery().then((bat) => {
                battery = bat
                battery.addEventListener('levelchange', handleChange)
                battery.addEventListener('chargingchange', handleChange)
                handleChange({ target: battery })
            })
        } else {
            this.battery = 100
        }

        let identifier = localStorage.getItem('umnion-identifier10')

        if (identifier) {
            this.identifier = identifier
        } else {
            this.identifier = this.randomString(10).toString('hex')
            localStorage.setItem('umnion-identifier10', this.identifier)
        }
    }

    randomString(length) {
        var result = ''
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        var charactersLength = characters.length
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return result
    }

    initSync() {
        // sync after 5 seconds
        setTimeout(() => {
            this.sync()
        }, 5000)

        // next sync in x seconds
        setTimeout(() => {
            this.initSync()
        }, 1000 * 60 * 5)
    }

    sync() {
        if (client.data && client.data.entry && client.data.entry.uid) {
            Api.post('ifbck/client/terminal-ping', {
                deviceId: this.identifier,
                entryId: client.data.entry.uid,
                battery: this.battery,
                // osVersion: this.getOS() + ' / ' + this.getBrowser(),
                version: this.version,
            }).then(
                (data) => Logger.log('terminal ping success', 'Syncer', data),
                (error) => Logger.log('terminal ping error', 'Syncer', error)
            )

            client.sendOfflineFeedbacks()
            if (this.syncsDone > 0) {
                client.checkUpdate()
            }

            this.syncsDone += 1
        }

        if (window.registration) {
            Logger.log('updating service worker', 'Syncer')
            window.registration.waiting.postMessage({ type: 'SKIP_WAITING' })

            if (client.isTerminal()) {
                window.location.reload()
            } else {
                this.onAppUpdateAvailable()
            }
        }
    }
}

const syncer = new Syncer()

export default syncer
