import Api from '../Api'
import localforage from 'localforage'
import clientParameters from './ClientParameters'
import Logger from '../helper/Logger'
import { translator } from './Translator'
import AppConfig from '../AppConfig'
import EventBehavior from '../EventBehavior'
import FieldObject from './FieldObject'
import urlManager from './UrlManager'

class Client extends EventBehavior {
    offlineStorage = localforage.createInstance({
        name: 'feedbackOfflineStorage',
    })

    eventNames = {
        clientUpdated: 'clientUpdated',
        placeChanged: 'placeChanged',
    }

    data = {
        md5: '',
        places: [],
        campaigns: [],
    }
    currentPlace = {
        name: '',
        uid: 0,
        id_parent_place: 0,
        place_type: 0,
    }
    currentCampaign = {
        name: '',
        uid: 0,
        fields: [],
        checkoutElements: [],
    }
    subPlaces = []
    callbacks = {}

    isCheckout = false

    customCheckoutUrl = ''

    constructor(data) {
        super()
        this.load(data)
    }

    /**
     * loading array into our data
     * @param data
     */
    load(data) {
        this.data = data

        // first location of array should be the entry
        if (this.data && this.data.places && Array.isArray(this.data.places) && this.data.places.length > 0) {
            this.currentPlace = this.data.places[0]
        }

        if (data && data.translations) {
            translator.loadTranslations(data.translations)
        }

        if (data && data.parameters) {
            clientParameters.loadData(data.parameters)
        }

        this.parameters = clientParameters
    }

    /**
     * getting client data, either from local file
     * or from web. checks for update if loaded locally
     * @param currentUrl
     * @param placeId
     * @param campaignId
     * @returns {Promise<unknown>}
     */
    getData(currentUrl, placeId, campaignId) {
        this.load(JSON.parse(localStorage.getItem('client' + currentUrl)))

        if (this.data && this.data.md5 && !AppConfig.skipOfflineCache) {
            this.selectCampaign(campaignId)

            this.selectLocation(placeId)

            this.dispatch(this.eventNames.clientUpdated)

            return this.checkAndLoadUpdate(currentUrl, placeId, campaignId, this.data.md5)
        } else {
            return this.loadDataFromServer(currentUrl, placeId, campaignId)
        }
    }

    /**
     * loading data from server
     * @param currentUrl
     * @param placeId
     * @param campaignId
     * @returns {Promise<unknown>}
     */
    loadDataFromServer(currentUrl, placeId, campaignId) {
        return new Promise((resolve, reject) => {
            Api.get('ifbck/client/get' + currentUrl).then(
                (result) => {
                    if (result) {
                        localStorage.setItem('client' + currentUrl, JSON.stringify(result))
                        this.load(result)

                        if (campaignId) {
                            this.selectCampaign(campaignId)
                        }
                        if (placeId) {
                            this.selectLocation(placeId)
                        }
                        this.dispatch(this.eventNames.clientUpdated)

                        resolve(result)
                    } else {
                        Logger.log('Client not found')
                        reject({ message: 'client not found' })
                    }
                },
                (error) => {
                    Logger.log('Error in loading client')
                    reject(error)
                }
            )
        })
    }

    /**
     * checking for update
     * @param currentUrl
     * @param placeId
     * @param campaignId
     * @param md5
     * @returns {Promise<unknown>}
     */
    checkAndLoadUpdate(currentUrl, placeId, campaignId, md5) {
        return new Promise((resolve) => {
            if (currentUrl === '/preview') {
                this.loadDataFromServer(currentUrl + '?placeId=' + placeId + '&campaignId=' + campaignId, placeId, campaignId).then(
                    (result) => {
                        resolve(result)
                    },
                    () => {
                        // no big deal
                    }
                )

                return
            }

            // try to get update via url
            Api.get('ifbck/client/get-md5' + currentUrl).then(
                (result) => {
                    if (result) {
                        if (result.md5 === md5) {
                            resolve(this.data)
                        } else {
                            this.loadDataFromServer(currentUrl, placeId, campaignId).then(
                                (result) => {
                                    resolve(result)
                                },
                                () => {
                                    // no big deal
                                }
                            )
                        }
                    }
                },
                () => {
                    // dont reject, its not a problem if we dont have a response yet
                    // (server might be offline, we might be offline)
                    // reject(error)
                }
            )
        })
    }

    checkUpdate() {
        this.checkAndLoadUpdate(urlManager.getEntry(), undefined, undefined, this.data.md5)
    }

    // place stuff
    getPlaceName() {
        if (this.currentPlace && this.currentPlace.name) {
            return this.currentPlace.name
        }

        if (this.data.places && Array.isArray(this.data.places) && this.data.places.length > 0) {
            return this.data.places[0].name
        }

        return ''
    }

    getCurrentPlaceId() {
        if (this.currentPlace && this.currentPlace.uid) {
            return parseInt(this.currentPlace.uid)
        }

        return 0
    }

    getSubPlaces() {
        if (this.currentPlace && this.data.places) {
            let currentPlaceId = parseInt(this.currentPlace.uid)
            return this.data.places.filter(function (place) {
                return parseInt(place.id_parent_place) === currentPlaceId
            })
        }

        return []
    }

    selectLocation(uid) {
        const id = parseInt(uid)
        if (id !== 0 && this.data && this.data.places) {
            if (this.currentPlace && this.currentPlace.uid && parseInt(this.currentPlace.uid) === id) {
                return
            }
            let filtered = this.data.places.filter(function (place) {
                return parseInt(place.uid) === id
            })

            if (filtered.length > 0) {
                this.currentPlace = filtered[0]
                this.subPlaces = this.getSubPlaces()

                this.dispatch(this.eventNames.placeChanged)
            }
        } else {
            this.setRootLocation()
        }
    }

    setRootLocation() {
        if (this.data && this.data.places && this.data.places.length > 0 && this.currentPlace.uid !== this.data.places[0].uid) {
            this.currentPlace = this.data.places[0]
            this.subPlaces = this.getSubPlaces()

            this.dispatch(this.eventNames.placeChanged)
        }
    }

    isPlace() {
        return Boolean(this.data && this.data.places && Array.isArray(this.data.places) && (this.data.places.length === 1 || parseInt(this.currentPlace.place_type) === 1))
    }

    // campaign stuff
    isCampaign() {
        return Boolean(
            this.data && this.isPlace() && this.data.campaigns && Array.isArray(this.data.campaigns) && (this.data.campaigns.length === 1 || (this.currentCampaign && this.currentCampaign.uid))
        )
    }

    getCampaignsList() {
        return this.data.campaigns
    }

    getCurrentCampaignId() {
        if (this.currentCampaign && this.currentCampaign.uid) {
            return parseInt(this.currentCampaign.uid)
        }
        if (this.isCampaign()) {
            return parseInt(this.data.campaigns[0].uid)
        }

        return 0
    }

    selectCampaign(uid) {
        const id = parseInt(uid)

        const wasCampaign = this.isCampaign()

        if (id !== 0 && this.data && this.data.campaigns) {
            let filtered = this.data.campaigns.filter(function (campaign) {
                return parseInt(campaign.uid) === id
            })

            if (filtered.length > 0) {
                if (this.currentCampaign && this.currentCampaign.uid && parseInt(filtered[0].uid) === parseInt(this.currentCampaign.uid)) {
                    return
                }

                this.currentCampaign = filtered[0]

                if (!wasCampaign) {
                    // notify router about selected campaign
                    this.dispatch(this.eventNames.clientUpdated)
                }

                return
            }
        }
        this.currentCampaign = null
        this.dispatch(this.eventNames.clientUpdated)
    }

    getCampaignFields(campaignId) {
        if (campaignId) {
            this.selectCampaign(campaignId)

            return this.currentCampaign.fields
        }

        if (this.currentCampaign) {
            return this.currentCampaign.fields
        }

        return []
    }

    getPagedCampiagnFields(campaignId) {
        if (campaignId) {
            this.selectCampaign(campaignId)
        }

        let pages = []

        let page = {
            id: 'main',
            items: [],
        }

        this.currentCampaign.fields.forEach((field) => {
            if (parseInt(field.field_type) === 12) {
                if (page.items.length > 0) {
                    pages.push(page)
                }
                page = {
                    id: 'page-' + field.uid,
                    items: [],
                    hidden: field.settings.hidden || false,
                }
            } else {
                if (field.localizations && field.localizations.de && field.localizations.de.placeholder === '10000') {
                    return
                }
                page.items.push(field)
            }
        })

        pages.push(page)

        return pages.filter((item) => !item.hidden)
    }

    submitFeedback() {
        const fields = this.getCampaignFields()
        let fieldData = {}

        fields.forEach((field) => {
            if (field.value !== undefined && field.value !== '') {
                fieldData[String(field.uid)] = field.value
            }

            // clean field values for next feedback
            field.value = ''
        })

        const info = {
            campaignId: this.getCurrentCampaignId(),
            placeId: this.getCurrentPlaceId(),
            entryId: this.data.entry.uid,
            fields: fieldData,
        }

        this.isCheckout = true

        this.dispatch(this.eventNames.clientUpdated)

        Logger.log("Sending feedback to server")

        // try to save feedback to server
        this.internalSaveFeedback(info).then(
            (data) => {
                this.handleFeedbackSavingResponse(data)
            },
            () => {
                // if it couldn't be stored
                this.storeFeedbackOffline(info.placeId, info.campaignId, info.entryId, info.fields)
            }
        )
    }

    idleTimeout() {
        if (!this.isTerminal()) {
            return
        }

        const fields = this.getCampaignFields()
        let requiredFieldsMissing = false,
            values = 0

        fields.forEach((field) => {
            if (field.value !== undefined && field.value !== '') {
                values += 1
            }

            if (field.required && (typeof field.value === 'undefined' || field.value === '' || field.value === undefined)) {
                requiredFieldsMissing = true
            }
        })

        if (values > 0 && !requiredFieldsMissing) {
            this.submitFeedback()
        } else {
            window.location.href = urlManager.getEntry()
        }
    }

    internalSaveFeedback(info) {
        return new Promise((resolve, reject) => {
            // request to server
            Api.post('ifbck/client/save-feedback', info).then(
                (data) => {
                    if (data) resolve(data)
                    else reject({ message: 'no response' })
                },
                (error) => {
                    reject(error)
                }
            )
        })
    }

    storeFeedbackOffline(placeId, campaignId, entryId, fields) {
        let id = this.generateUniqueId()

        Logger.log("Storing feedback offline")

        // storing feedback offline
        this.offlineStorage
            .setItem('feedback-' + id, {
                id: id,
                campaignId: campaignId,
                placeId: placeId,
                entryId: entryId,
                fields: fields,
                tstamp: Math.floor(new Date().getTime() / 1000),
            })
            .then(
                () => {},
                () => {
                    // do nothing, just avoid error throwing
                }
            )
    }

    sendOfflineFeedbacks() {
        // for each offline stored feedback
        this.offlineStorage.iterate((feedback, key) => {
            // try to send feedback again
            this.internalSaveFeedback(feedback, true).then(
                () => {
                    // on success of saving feedback
                    // remove feedback from offline storage
                    this.offlineStorage.removeItem(key)
                },
                () => {
                    // do nothing, just avoid error throwing
                }
            )
        })
    }

    handleFeedbackSavingResponse(response) {
        setTimeout(() => {
            if (response.satisfied !== null) {
                const satisfiedElements = Array.from(document.getElementsByClassName('satisfied-block'))
                const unsatisfiedElements = Array.from(document.getElementsByClassName('unsatisfied-block'))

                satisfiedElements.forEach((e) => {
                    response.satisfied ? e.classList.add('visible') : e.classList.remove('visible')
                })

                unsatisfiedElements.forEach((e) => {
                    response.satisfied ? e.classList.remove('visible') : e.classList.add('visible')
                })
            }
        }, 1000)
    }

    dec2hex (dec) {
        return dec.toString(16).padStart(2, "0")
    }

    generateUniqueId(len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }

    isTerminal() {
        return Boolean(this.data && this.data.entry && this.data.entry.extendedinfo)
    }

    log(info, data) {
        Logger.log(info, 'Client', data)
    }

    getField(fieldId) {
        fieldId = parseInt(fieldId)
        if (this.currentCampaign) {
            let filtered = this.currentCampaign.fields.filter((field) => {
                return parseInt(field.uid) === fieldId
            })

            if (filtered.length > 0) {
                return filtered[0]
            }
        }

        return null
    }

    showField(fieldId) {
        let field = this.getField(fieldId)

        if (field) {
            if (field.object instanceof FieldObject) {
                field.object.show()
            } else {
                const previousHidden = field.settings.hidden
                field.settings.hidden = false

                if (previousHidden !== field.settings.hidden && parseInt(field.field_type) === 12) {
                    this.pagesChanged()
                }
            }
        }
    }

    hideField(fieldId) {
        let field = this.getField(fieldId)

        if (field) {
            if (field.object instanceof FieldObject) {
                field.object.hide()
            } else {
                const previousHidden = field.settings.hidden
                field.settings.hidden = true

                if (previousHidden !== field.settings.hidden && parseInt(field.field_type) === 12) {
                    this.pagesChanged()
                }
            }
        }
    }

    nextPage = () => {}
    pagesChanged = () => {}
}

const client = new Client()

export default client
