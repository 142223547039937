import Logger from '../helper/Logger'
import AppConfig from '../AppConfig'

class UrlManager {
    getEntry() {
        const index = AppConfig.baseUrl !== '' ? 2 : 1
        let entry = window.location.pathname.split('/')[index] ?? ''
        let url = '/' + entry
        if (url === '/ifbck') url = '/' + window.location.pathname.split('/')[index + 1]

        Logger.log('url', 'UrlManager', url)

        return url
    }

    hasHistory() {
        return Boolean(window.history.length)
    }

    isBase() {
        return this.getEntry() === window.location.pathname
    }

    goBack() {
        window.history.back()
    }
}

let urlManager = new UrlManager()
export { UrlManager, urlManager }
export default urlManager
