import React, { Component } from 'react'
import clientParameters from './ClientParameters'

class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isCheckout: props.isCheckout,
        }
    }

    render() {
        const { isCheckout } = this.state

        const parameters = clientParameters.data

        const headerImageUrl = isCheckout ? parameters.app_checkout_header || parameters.app_header_image : parameters.app_header_image

        const checkoutImageUrl = isCheckout ? parameters.app_checkout_image || null : null

        return (
            <>
                <div
                    className="row justify-content-center"
                    style={{
                        marginLeft: '-0.75rem',
                        marginRight: '-0.75rem',
                    }}
                >
                    <div className="col p-0">
                        {headerImageUrl && (
                            <div
                                className="ratio-21x9"
                                style={{
                                    objectFit: 'cover',
                                    // maxHeight: 200,
                                    overflow: 'hidden',
                                    position: 'relative',
                                }}
                            >
                                <img src={'https://ifbck.com/' + headerImageUrl} className="img-fluid w-100" alt="" />

                                {checkoutImageUrl && (
                                    <>
                                        <br />
                                        <img src={'https://ifbck.com/' + checkoutImageUrl} className="img-fluid w-100" alt="" />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <br />
            </>
        )
    }
}

export default Header
