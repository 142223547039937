import { BaseFormField, FieldWrapper } from './Base'
import clientStyles from '../ClientStyles'
import BackingInput from './helper/BackingInput'
import Logger from '../../helper/Logger'

class CheckboxList extends BaseFormField {
    values = []

    constructor(props) {
        super(props)

        if (this.state.value) {
            this.values = this.state.value.split(',')
        }

        Object.assign(this.state, {
            validated: this.isValid(),
        })
    }

    addValue(value) {
        let index = this.values.indexOf(value)
        if (index !== -1) {
            return
        }
        index = this.values.indexOf(String(value))
        if (index !== -1) {
            return
        }
        this.values.push(value)
        this.setValue(this.values.join())

        this.validate()
    }

    removeValue(value) {
        let index = this.values.indexOf(value)
        if (index !== -1) {
            this.values.splice(index, 1)
            this.setValue(this.values.join())
        }
        index = this.values.indexOf(String(value))
        if (index !== -1) {
            this.values.splice(index, 1)
            this.setValue(this.values.join())
        }

        this.validate()
    }

    validate() {
        this.setState({
            validated: this.isValid(),
        })
    }

    isValid() {
        const [min, max] = this.state.field.getMinMaxOptions()

        return (min <= this.values.length || !min) && (max >= this.values.length || !max)
    }

    render() {
        const { field, validated, required } = this.state

        Logger.log('field is required: ' + (required ? 1 : 0), 'CheckboxList')
        Logger.log('field is hidden: ' + (field.isHidden() ? 1 : 0), 'CheckboxList')

        return (
            <FieldWrapper field={field}>
                {required && !field.isHidden() && <BackingInput uid={field.getUid()} validated={validated} />}
                <div className={'form-check form-switch w-100 ' + clientStyles.getCheckboxClass()}>
                    {field.getOptions().map((option, index) => (
                        <div key={'option' + index}>
                            <input
                                className={'form-check-input'}
                                // name={'field-' + field.getUid()}
                                type="checkbox"
                                id={'field-' + field.getUid() + '-' + index + '-checkbox'}
                                defaultChecked={
                                    this.values.indexOf(index) !== -1 || this.values.indexOf(String(index)) !== -1
                                }
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        this.addValue(index)
                                    } else {
                                        this.removeValue(index)
                                    }
                                }}
                                required={validated || field.isHidden() ? 0 : 1}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={'field-' + field.getUid() + '-' + index + '-checkbox'}
                            >
                                {option}
                            </label>
                        </div>
                    ))}
                </div>
            </FieldWrapper>
        )
    }
}

export default CheckboxList
