import React, { Component } from 'react'
import urlManager from './UrlManager'
import client from './Client'
import LoadingAction from './LoadingAction'
import { t } from './Translator'
import FormStyles from './FormStyles'
import './Checkout.css'
import AppConfig from '../AppConfig'
import qs from 'qs'
import Header from './Header'
import clientParameters from './ClientParameters'
import Logger from '../helper/Logger'

const currentUrl = urlManager.getEntry()

let startUrl = (AppConfig.baseUrl ? '/' : '') + AppConfig.baseUrl + currentUrl

if (qs.parse(window.location.search, { ignoreQueryPrefix: true }).previousUrl) {
    startUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true }).previousUrl
}

class Checkout extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loading: false,
            })
        }, 1000)

        if (client.isTerminal()) {
            let clientTimeout = parseInt(clientParameters.data.checkout_timeout || 10000 + '')

            Logger.log('setting timeout', 'Checkout', clientTimeout)

            setTimeout(() => {
                window.location.href = startUrl
            }, 1000 + clientTimeout)
        }
    }

    render() {
        const { loading } = this.state

        if (loading) {
            return (
                <>
                    <Header isCheckout={true} />

                    <LoadingAction />

                    <FormStyles />
                </>
            )
        }

        var checkoutText = (
            <>
                <h3>{t('Thank you for your feedback')}</h3>
                <h5>{t('Your feedback will now be forwarded to the management')}</h5>
            </>
        )

        if (client.currentCampaign.checkoutElements.length > 0) {
            const checkoutElements = client.currentCampaign.checkoutElements

            if (checkoutElements[0].content_type === 8) {
                checkoutText = <div dangerouslySetInnerHTML={{ __html: checkoutElements[0].html }} />
            }
        }

        return (
            <>
                <Header isCheckout={true} />

                <div className="row justify-content-center mb-3">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                        <br />
                        <br />
                        {checkoutText}
                        <br />
                        <br />
                    </div>
                    <div className="text-center">
                        <a href={startUrl} className="btn btn-primary">
                            Start
                        </a>
                    </div>

                    <FormStyles />
                </div>
            </>
        )
    }
}

export default Checkout
