class EventBehavior {
    /**
     * adding event handler
     * @param name
     * @param callback
     * @param specifier
     */
    on(name, callback, specifier) {
        if (specifier) {
            let callbacks = this[name]
            if (!callbacks) this[name] = { specifier: callback }
            else callbacks[specifier] = callback
        } else {
            let callbacks = this[name]
            if (!callbacks) this[name] = [callback]
            else callbacks.push(callback)
        }
    }

    /**
     * removing event handler
     * @param name
     * @param specifier
     */
    off(name, specifier) {
        if (specifier) {
            if (this[name][specifier]) {
                this[name][specifier] = null
            }
        } else {
            if (this[name]) {
                this[name] = []
            }
        }
    }

    /**
     * executing event handler, should not be called externally
     * @param name
     * @param event
     */
    dispatch(name, event) {
        let callbacks = this[name]

        if (callbacks) callbacks.forEach((callback) => callback(event))
    }
}

export default EventBehavior
