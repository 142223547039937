import { Component } from 'react'
import FieldObject from '../FieldObject'
import ClientStyles from '../ClientStyles'
import { translator } from '../Translator'
import { Form } from 'react-bootstrap'
import clientStyles from '../ClientStyles'
import Logger from '../../helper/Logger'
// import client from '../Client'

class BaseFormField extends Component {
    constructor(props) {
        super(props)

        if (props.field instanceof FieldObject) {
            this.state = {
                field: props.field,
                label: props.field.getLabel(),
                description: props.field.getDescription(),
                placeholder: props.field.getPlaceholder(),
                value: props.field.data.value || '',
                hidden: props.field.isHidden(),
                required: props.field.isRequired(),
            }
        } else {
            let fieldObject = new FieldObject(props.field)
            this.state = {
                field: fieldObject,
                label: fieldObject.getLabel(),
                description: fieldObject.getDescription(),
                placeholder: fieldObject.getPlaceholder(),
                value: fieldObject.data.value,
                hidden: fieldObject.isHidden(),
                required: fieldObject.isRequired(),
            }
        }

        this.handleChange = this.handleChange.bind(this)
        this.setValue = this.setValue.bind(this)
    }

    componentDidMount() {
        this._ismounted = true
        translator.on('languageChanged', () => {
            this.state.field.loadLanguage(translator.language)

            if (!this._ismounted) {
                return
            }

            this.languageChanged()
        })
        this.state.field.on('visualChanged', () => {
            if (this._ismounted) {
                this.visualChanged()
            }
        })
    }

    componentWillUnmount() {
        this._ismounted = false
    }

    visualChanged() {
        this.setState({
            hidden: this.state.field.isHidden(),
            required: this.state.field.isRequired(),
        })
    }

    languageChanged() {
        this.setState({
            label: this.state.field.getLabel(),
            description: this.state.field.getDescription(),
            placeholder: this.state.field.getPlaceholder(),
        })
    }

    handleChange(event) {
        Logger.log('changing field value: ' + event.target.value, 'BaseField')
        this.setValue(event.target.value)
    }

    setValue(value) {
        this.state.field.setValue(value)
        this.state.field.performActions()
        this.setState({
            value: value,
        })
        // client.setValueForField(this.state.field.data.uid, value)
    }
}

class FieldWrapper extends BaseFormField {
    hideLabel = false

    render() {
        const { hidden } = this.state

        let fieldWrapperClasses = 'row justify-content-center align-items-center mb-3 animated-field'

        if (hidden) {
            fieldWrapperClasses += ' hidden-field'
        }

        if (this.props.centeredStyle) {
            fieldWrapperClasses += ' centered'
        }

        if (this.props.className) {
            fieldWrapperClasses += ' ' + this.props.className
        }

        return (
            <div className={fieldWrapperClasses}>
                {!this.props.hideLabel && <FieldLabel field={this.state.field} xlStyle={this.props.centeredStyle ? 'centered' : ''} />}
                {(!this.props.hideWrapper && (
                    <div className={clientStyles.getFieldWrapperClasses(this.props.centeredStyle) + this.state.field.customFieldWrapperClasses()}>
                        {this.props.children}
                        {this.state.field.isRequired() && <Form.Control.Feedback type="invalid">{this.state.field.getInvalidText()}</Form.Control.Feedback>}
                    </div>
                )) ||
                    this.props.children}
            </div>
        )
    }
}

class FieldLabel extends BaseFormField {
    render() {
        const { label } = this.state
        const labelClasses =
            'form-label col-12 col-sm-10 col-md-9 col-lg-8 ' +
            (this.props.xlStyle === 'centered' ? 'col-xl-9 text-center' : 'col-xl-3 text-xl-end') +
            ' col-form-label' +
            ClientStyles.getLabelClass() +
            (label === 'sp' ? ' d-none d-xl-block invisible' : '')

        return (
            (label && (
                <label htmlFor={'field-' + this.state.field.getUid()} className={labelClasses}>
                    {this.props.children || label}
                </label>
            )) ||
            null
        )
    }
}

export { FieldWrapper, FieldLabel, BaseFormField }

export default BaseFormField
