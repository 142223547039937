window.enableLogging = 0

class Logger {
    static log(info, classname, data) {
        if (process.env.NODE_ENV === 'production' && !(window.enableLogging === 1)) return

        let logString = ''

        if (classname) {
            logString += ' ' + classname + ' | '
        }

        logString += info

        if (data) {
            if (Array.isArray(data) || typeof data === 'object') {
                console.log(logString)
                console.log(data)
            } else {
                logString += ': ' + data
                console.log(logString)
            }
        } else {
            console.log(logString)
        }

        let container = document.getElementById('logoutput')

        if (container !== null && typeof container !== 'undefined') {
            let content = document.createElement('li')
            content.textContent = logString
            container.textContent = logString
        }
    }
}

export default Logger
