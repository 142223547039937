import 'whatwg-fetch'

class Api {
    static get(url) {
        return this.request(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
    }

    static post(url, data) {
        return this.request(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
    }

    static request(url, requestOptions) {
        return new Promise((success, reject) => {
            fetch(process.env.REACT_APP_CLIENT_API_URL + url, requestOptions).then(
                (response) => {
                    success(response.json())
                },
                (error) => {
                    reject(error)
                }
            )
        })
    }
}

const api = new Api()

export { api }

export default Api
