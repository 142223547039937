import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'

import client from './Client'
import urlManager from './UrlManager'
import clientStyles from './ClientStyles'

class PlaceSelection extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            isLoaded: false,
            client: client,
            subPlaces: [],
        }

        this.state.client = client
        this.state.subPlaces = client.getSubPlaces()
    }

    componentDidMount() {
        const that = this

        client.on(client.eventNames.placeChanged, () => {
            that.setState({
                client: client,
                subPlaces: client.subPlaces,
            })
        })
    }

    componentWillUnmount() {
        client.off(client.eventNames.placeChanged)
    }

    switchLocation(uid) {
        // just add the location id to url, the AppRouter will do the rest and we will be notified by callback
        this.props.history.push(urlManager.getEntry() + '/locations/' + uid)
    }

    render() {
        const { client, subPlaces } = this.state
        return (
            <>
                <br />
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                        <h3>{client.getPlaceName()}</h3>
                        <ListGroup className="shadow place-list">
                            {subPlaces.map((item) => (
                                <ListGroup.Item
                                    action
                                    key={item.uid}
                                    className={clientStyles.getCheckboxClass()}
                                    onClick={() => {
                                        this.switchLocation(item.uid)
                                    }}
                                >
                                    {item.name}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                </div>

                <br />
                <br />
            </>
        )
    }
}

export default withRouter(PlaceSelection)
